import { PageProps } from "gatsby";
import React from "react";

import { Intervals } from "../components/pages/Intervals";

import { snProps } from "../js/utils";

const IntervalsPage = (props: PageProps) => (
  <Intervals {...{ ...snProps, ...props }} />
);

export default IntervalsPage;
